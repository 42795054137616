<template>
    <div>
        <v-row>
            <v-col sm="6" v-if="order.id">
                <v-card>
                    <v-card-title>Общая информация</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col sm="4" class="py-2">Дата</v-col>
                            <v-col sm="8" class="py-2">{{ order.created_at | moment('DD.MM.YYYY HH:mm') }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" class="py-2">Статус</v-col>
                            <v-col sm="8" class="py-2 d-flex justify-space-between align-center">
                                <span class="true-false-label primary" :style="`background: ${ statuses[order.status].color }`">
                                    {{ statuses[order.status].title }}
                                </span>
                                <v-menu offset-y left>
                                    <template #activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                            <v-icon color="primary">mdi-pencil</v-icon>
                                        </div>
                                    </template>
                                    <v-list>
                                        <template v-for="(item, status) of statuses">
                                            <v-list-item :key="`status-${ status }`" @click="setStatus(status)">
                                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" class="py-2">Пользователь</v-col>
                            <v-col sm="8" class="py-2">
                                {{ order.user.name }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" class="py-2">Телефон</v-col>
                            <v-col sm="8" class="py-2">
                                {{ order.user.phone }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" class="py-2">Email</v-col>
                            <v-col sm="8" class="py-2">
                                {{ order.user.email }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4" class="py-2">Сумма</v-col>
                            <v-col sm="8" class="py-2">{{ priceFormated(order.full_price) }}</v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col sm="6">
                <v-data-table
                    :headers="statusHeaders"
                    :items="order.status_change"
                    class="elevation-1"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>История статусов</v-toolbar-title>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.change_at`]="{ item }">
                        {{ item.change_at | moment('DD.MM.YYYY HH:mm') }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <span class="true-false-label primary" :style="`background: ${ statuses[item.status].color }`">
                            {{ statuses[item.status].title }}
                        </span>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="order.products && order.products.length">
            <v-col>
                <v-data-table
                    :headers="productHeaders"
                    :items="order.products"
                    class="elevation-1"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Продукты</v-toolbar-title>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.title`]="{ item }">
                        {{ item.product.title }}
                    </template>
                    <template v-slot:[`item.size`]="{ item }">
                        {{ item.size.title }}
                    </template>
                    <template v-slot:[`item.full_price`]="{ item }">
                        {{ priceFormated(item.full_price) }}
                    </template>
                    <template v-slot:[`item.result_price`]="{ item }">
                        {{ priceFormated(item.full_price * item.quantity) }}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated } from '../../helpers';
    

    export default {
        name: 'OrderDetail',

        components: {
        },

        data() {
            return {
                imageSrc,
                priceFormated,
                deleteModal: false,
                statusHeaders: [
                    { text: 'Дата', value: 'change_at', sortable: true, filterable: false },
                    { text: 'Статус', value: 'status', sortable: false, filterable: false },
                    { text: 'Пользователь', value: 'user_name', sortable: false, filterable: false },
                ],
                productHeaders: [
                    { text: 'Название', value: 'title', sortable: true, filterable: false },
                    { text: 'Размер', value: 'size', sortable: false, filterable: false },
                    { text: 'Количество', value: 'quantity', sortable: true, filterable: false },
                    { text: 'Стоимость', value: 'full_price', sortable: true, filterable: false },
                    { text: 'Сумма', value: 'result_price', sortable: false, filterable: false },
                ],
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('orders', {
                statuses: state => state.statuses,
                order: state => state.entity
            }),
            isAdmin() {
                return this.user.admin;
            }
        },

        async mounted() {
            const id = this.$route.params.id;
            await store.dispatch('orders/statuses');
            if(id) {
                await store.dispatch('orders/get', {id});
            }
        },

        methods: {
            async setStatus(status) {
                await store.dispatch('orders/setStatus', { id: this.order.id, status });
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                this.$router.push({ path: '/orders' });
            },
        },
    };
</script>